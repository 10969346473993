var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "home-btn d-none d-sm-block" },
      [
        _c(
          "router-link",
          { staticClass: "text-dark", attrs: { tag: "a", to: "/" } },
          [_c("i", { staticClass: "fas fa-home h2" })]
        )
      ],
      1
    ),
    _c("div", { staticClass: "account-pages my-5 pt-5" }, [
      _c("div", { staticClass: "container" }, [_vm._t("default")], 2)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }