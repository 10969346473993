var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Layout", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8 col-lg-6 col-xl-5" }, [
        _c("div", { staticClass: "card overflow-hidden" }, [
          _c("div", { staticClass: "bg-soft-primary" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-7" }, [
                _c("div", { staticClass: "text-primary p-4" }, [
                  _c("h5", { staticClass: "text-primary" }, [
                    _vm._v("Reset Password")
                  ]),
                  _c("p", [_vm._v("Re-Password with Skote.")])
                ])
              ]),
              _c("div", { staticClass: "col-5 align-self-end" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: {
                    src: require("@/assets/images/profile-img.png"),
                    alt: ""
                  }
                })
              ])
            ])
          ]),
          _c("div", { staticClass: "card-body pt-0" }, [
            _c(
              "div",
              [
                _c("router-link", { attrs: { tag: "a", to: "/" } }, [
                  _c(
                    "div",
                    { staticClass: "avatar-md profile-user-wid mb-4" },
                    [
                      _c(
                        "span",
                        { staticClass: "avatar-title rounded-circle bg-light" },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/logo.svg"),
                              alt: "",
                              height: "34"
                            }
                          })
                        ]
                      )
                    ]
                  )
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "p-2" },
              [
                _c(
                  "b-alert",
                  {
                    staticClass: "mb-4",
                    attrs: { variant: "danger", dismissible: "" },
                    model: {
                      value: _vm.isResetError,
                      callback: function($$v) {
                        _vm.isResetError = $$v
                      },
                      expression: "isResetError"
                    }
                  },
                  [_vm._v(_vm._s(_vm.error))]
                ),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.tryToReset($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "useremail" } }, [
                        _vm._v("Email")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.submitted && _vm.$v.email.$error
                        },
                        attrs: {
                          type: "email",
                          id: "useremail",
                          placeholder: "Enter email"
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value
                          }
                        }
                      }),
                      _vm.submitted && _vm.$v.email.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.email.required
                              ? _c("span", [_vm._v("Email is required.")])
                              : _vm._e(),
                            !_vm.$v.email.email
                              ? _c("span", [
                                  _vm._v("Please enter valid email.")
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "form-group row mb-0" }, [
                      _c("div", { staticClass: "col-12 text-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary w-md",
                            attrs: { type: "submit" }
                          },
                          [_vm._v("Reset")]
                        )
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "mt-5 text-center" }, [
          _c(
            "p",
            [
              _vm._v(" Remember It ? "),
              _c(
                "router-link",
                {
                  staticClass: "font-weight-medium text-primary",
                  attrs: { tag: "a", to: "/login" }
                },
                [_vm._v("Sign In here")]
              )
            ],
            1
          ),
          _c("p", [
            _vm._v(
              " © " + _vm._s(new Date().getFullYear()) + " Skote. Crafted with "
            ),
            _c("i", { staticClass: "mdi mdi-heart text-danger" }),
            _vm._v(" by Themesbrand ")
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }